/**
 * 方法说明：在新增、修改的页面引入该mixin，避免相同的方法重复书写；
 */
import FormFrame from '@/components/frame/FormFrame.vue';

export default {
  components: {
    FormFrame,
  },
  data() {
    return {
    };
  },
  computed: {
    paramsId() {
      return this.$route.params.id;
    },
    // 区分编辑 还是查看
    isDetail() {
      return this.$route.name.includes('Info');
    }
  },
  methods: {
    // 返回：如果没有设置列表页面，返回到上一个路由页面
    handleBack() {
      const listRouteName = this.$route.meta && this.$route.meta.activeMenu;
      if (listRouteName) {
        this.$router.push({
          name: listRouteName
        });
      } else {
        this.$router.go(-1);
      }
    },

    // 重置：添加时候是重置，编辑的时候是重新拉取数据，（！注意方法名称是getData)
    handleReset(form = 'form') {
      if (this.paramsId) {
        this.getData(this.paramsId);
      } else {
        this.$refs[form].resetFields();
      }
    },
  },
};
