<template>
  <div class="base-content">
    <form-frame
      :reset-visible="!isDetail"
      :submit-visible="!isDetail"
      @on-back="handleBack"
      @on-reset="handleReset"
      @on-submit="handleSubmit"
    >
      <div slot="info" v-if="paramsId">
        <span class="mr-20">所属门店：{{form.shopName}}</span>
        <!--<span class="mr-20">创建人：{{form.createUserName}}</span>-->
        <!--<span class="mr-20">创建时间：{{form.createTime}}</span>-->
      </div>
      <div slot="content">
        <a-card title="角色信息">
          <a-form-model
            ref="form"
            :model="form"
            :rules="rules"
            :label-col="{span:8}"
            :wrapper-col="{span:14}">
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="角色名称" prop="roleName">
                  <a-input v-model="form.roleName" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="10">
                <a-form-model-item label="角色说明" prop="remark">
                  <a-input v-model="form.remark" type="textarea" :disabled="isDetail"></a-input>
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="20">
                <a-form-model-item label="权限设置" prop="menuIds" :label-col="{span: 4}" :wrapper-col="{span: 15}">
                  <a-tree
                    v-model="form.menuIds"
                    checkable
                    :expanded-keys="expandedKeys"
                    :auto-expand-parent="autoExpandParent"
                    :selected-keys="selectedKeys"
                    :tree-data="treeData"
                    :replace-fields="replaceFields"
                    :disabled="isDetail"
                    :defaultExpandAll="true"
                    @expand="onExpand"
                    @select="onSelect"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
        </a-card>
      </div>
    </form-frame>
  </div>
</template>

<script>
import { roleOwnAll, roleInfo, roleSubmit } from '@/api/user';
import FormFrame from '@/components/frame/FormFrame.vue';
import formMixin from '@/mixin/formMixin';

export default {
  name: 'RoleForm',
  mixins: [formMixin],
  components: {
    FormFrame,
  },
  props: {},
  data() {
    return {
      form: {
        roleName: '',
        checkedKeys: [],
        remark: '',
        tree: ''
      },
      rules: {
        roleName: [
          {
            required: true,
            message: '请输入角色名称'
          }
        ],
      },
      expandedKeys: [],
      autoExpandParent: true,
      selectedKeys: [],
      treeData: [],
      replaceFields: {
        children: 'children',
        title: 'name',
        key: 'id'
      },
    };
  },
  computed: {

  },
  watch: {},
  created() {
    if (this.paramsId) {
      this.getData(this.paramsId);
    }

    this.getRoleOwnAll();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 会员信息
    getData(id) {
      roleInfo(id).then((res) => {
        this.form = res;
      });
    },

    // 获取权限列表
    getRoleOwnAll() {
      roleOwnAll().then(data => {
        this.treeData = data;
      });
    },

    // 保存
    handleSave() {
      const params = { ...this.form };
      roleSubmit(params).then((res) => {
        this.$message.success('操作成功！');
        this.handleBack();
      }).catch((error) => {
        console.log(error);
      });
    },

    // 提交
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.handleSave();
        } else {
          this.$message.error('请按要求填写');
          return false;
        }
      });
    },

    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onCheck(checkedKeys) {
      this.form.checkedKeys = checkedKeys;
    },
    onSelect(selectedKeys, info) {
      this.selectedKeys = selectedKeys;
    },
  }
};
</script>

<style lang="less">
  .ant-tree li ul {
    display: flex;
    flex-flow: row wrap;
  }
</style>
